import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {TextField, InputBase} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      borderBottom: '1px red'
    },
  },
}));

const StyledTextField = (props) => {
  const classes = useStyles();
  return (
    <TextField id="standard-basic" helperText={props.helperText} error={props.error} variant={props.variant} onChange={props.onChange} fullWidth={props.fullWidth} className={classes.textField} label={props.label} />
  )
}

StyledTextField.defaultProps = {
  label: "TextField",
  color: "primary",
  helperText: "",
  fullWidth: false,
  error: false,
  variant: 'filled'
}

export default StyledTextField