import * as React from "react"

function DashDial(props) {
  return (
    <svg width="100%" style={{minWidth: `420px`, maxWidth: `800px`}} viewBox="0 0 408 346" {...props}>
      <defs>
        <filter
          x="-14.5%"
          y="-14.5%"
          width="129.0%"
          height="129.0%"
          filterUnits="objectBoundingBox"
          id="diala"
        >
          <feGaussianBlur stdDeviation={12} in="SourceGraphic" />
        </filter>
        <path id="dialc" d="M0 167L129 167 94.469697 282 0 282z" />
        <filter
          x="-54.3%"
          y="-43.5%"
          width="208.5%"
          height="221.7%"
          filterUnits="objectBoundingBox"
          id="dialb"
        >
          <feOffset dy={20} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={20}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path id="diale" d="M27 147H328V256H27z" />
        <filter
          x="-23.3%"
          y="-45.9%"
          width="146.5%"
          height="228.4%"
          filterUnits="objectBoundingBox"
          id="diald"
        >
          <feOffset dy={20} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={20}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <path
          id="dialg"
          d="M223 143L328 143 328 261 284.876465 261 223 261 213 201.164398z"
        />
        <filter
          x="-60.9%"
          y="-42.4%"
          width="221.7%"
          height="218.6%"
          filterUnits="objectBoundingBox"
          id="dialf"
        >
          <feOffset dy={20} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={20}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <filter
          x="-200.0%"
          y="-200.0%"
          width="500.0%"
          height="500.0%"
          filterUnits="objectBoundingBox"
          id="dialh"
        >
          <feGaussianBlur stdDeviation={8} in="SourceGraphic" />
        </filter>
        <circle id="dialj" cx={35.5552} cy={20.5207362} r={6} />
        <filter
          x="-145.8%"
          y="-104.2%"
          width="391.7%"
          height="391.7%"
          filterUnits="objectBoundingBox"
          id="diali"
        >
          <feOffset dy={5} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g transform="translate(40 4)">
          <circle
            stroke={props.color}
            strokeWidth={7.5}
            opacity={0.49736759}
            filter="url(#diala)"
            cx={174.25}
            cy={168.25}
            r={137.25}
          />
          <circle
            stroke={props.color}
            strokeWidth={7.5}
            fillOpacity={0.0176481234}
            fill={props.color}
            cx={179.25}
            cy={148.25}
            r={137.25}
          />
          <text
            fontFamily="Skumfuk"
            fontSize={37.5}
            fontWeight="normal"
            fill="#FFF"
            fillOpacity={0.9}
          >
            <tspan x={140} y={86.5} opacity="0.2">
              {props.asset}
            </tspan>
            <tspan x={85} y={126.5} >
              ${props.value}
            </tspan>
          </text>
          <use fill="#000" filter="url(#dialb)" xlinkHref="#c" />
          <use fill="#151616" xlinkHref="#dialc" />
          <g fill="#000">
            <use filter="url(#diald)" xlinkHref="#diale" />
            <use xlinkHref="#diale" />
          </g>
          <use fill="#000" filter="url(#dialf)" xlinkHref="#dialg" />
          <use fill="#0A0A0A" xlinkHref="#dialg" />
          <text
            fontFamily="ArialMT, Arial"
            fontSize={8}
            fontWeight="normal"
            fill="#FFF"
          >
            <tspan x={41} y={165} fillOpacity={0.5}>
              {"Starting Account Value:"}
            </tspan>
            <tspan x={41} y={178} fontSize={12} fillOpacity={0.9}>
              ${props.startingvalue}
            </tspan>
          </text>
          <text
            fontFamily="ArialMT, Arial"
            fontSize={8}
            fontWeight="normal"
            fill="#FFF"
          >
            <tspan x={41} y={195} fillOpacity={0.5}>
              {"Last Trade:"}
            </tspan>
            <tspan x={41} y={207} fontSize={12}>
              {props.lasttrade}
            </tspan>
          </text>
          <text
            fontFamily="ArialMT, Arial"
            fontSize={8}
            fontWeight="normal"
            fill="#FFF"
          >
            <tspan x={41} y={225} fillOpacity={0.5}>
              {"Last Signal:"}
            </tspan>
            <tspan x={41} y={237} fontSize={12}>
              {props.lastsignal}
            </tspan>
          </text>
          <text
            fontFamily="ArialMT, Arial"
            fontSize={12}
            fontWeight="normal"
            fill="#FFF"
            fillOpacity={0.9}
          >
            <tspan x={252.500977} y={173}>
              {"PROFIT"}
            </tspan>
          </text>
          <text
            fontFamily="ArialMT, Arial"
            fontSize={25}
            fontWeight="normal"
            fill={props.gainlossperc > 0 ? '#0DFF15' : '#4F4F4F'}
          >
            <tspan x={249.481689} y={206}>
              {props.gainlossperc}%
            </tspan>
          </text>
          <text
            opacity={0.5}
            fontFamily="ArialMT, Arial"
            fontSize={12}
            fontWeight="normal"
            fill="#FFF"
            fillOpacity={0.5}
          >
            <tspan x={16} y={272}>
             
            </tspan>
          </text>
          <text
            opacity={0.5}
            transform="rotate(-90 22 201.5)"
            fontFamily="ArialMT, Arial"
            fontSize={12}
            fontWeight="normal"
            fill="#FFF"
            fillOpacity={0.5}
          >
            <tspan x={-28} y={198} style={{textTransform: `uppercase`}}>
              {props.exchange}
            </tspan>
          </text>
          <g transform="translate(220.445 .48)">
            <path
              fill="#000"
              d="M27.0696 6.03961325e-14L0 25.5851362 3.5552 56.5971362 66.7468 46.2391362 75.2036 25.5851362z"
            />
            <text
              fontFamily="ArialMT, Arial"
              fontSize={8}
              fontWeight="normal"
              fill="#FFF"
              fillOpacity={0.9}
            >
              <tspan x={20.0122313} y={39.5207362}>
                {props.active ? 'IN PLAY' : 'IN CASH'}
              </tspan>
            </text>
            <circle
              fill={props.active ? "#0DE415" : "#4F4F4F"}
              filter="url(#dialh)"
              cx={35.5552}
              cy={20.5207362}
              r={6}
            />
            <use fill="#000" filter="url(#diali)" xlinkHref="#dialj" />
            <use fill={props.active ? "#0DE415" : "#4F4F4F"} xlinkHref="#dialj" />
          </g>
        </g>
        <text
          fontFamily="ArialMT, Arial"
          fontSize={16}
          fontWeight="normal"
          fill={props.gainloss > 0 ? "#0DFF15" : "#4F4F4F"}
          transform="translate(40 4)"
        >
          <tspan x={props.gainloss.toString().length <= 3 ? 258.910156 : 250} y={230}>
            {props.gainloss >= 0 ? '$' : '-$'}{props.gainloss < 0 ? props.gainloss * -1 : props.gainloss}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default DashDial
