import * as React from "react"

function Binance(props) {
  return (
    <svg style={{maxWidth: props.maxwidth ? props.maxwidth : '' }} viewBox="0 0 2500 2500" {...props}>
      <g fill={props.color ? props.color : '#F3BA2F'} fillRule="nonzero" stroke="none" strokeWidth={1}>
        <path d="M764.48 1050.52L1250 565l485.75 485.73 282.5-282.5L1250 0 482 768l282.49 282.5M0 1250l282.51-282.55L565 1249.94l-282.51 282.51L0 1250zm764.48 199.51L1250 1935l485.74-485.72 282.65 282.35-.14.15L1250 2500l-768-768-.4-.4 282.91-282.12M1935 1250.12l282.51-282.51L2500 1250.1l-282.5 282.51-282.5-282.49z" />
        <path d="M1536.52 1249.85L1536.64 1249.85 1250 963.19 1038.13 1175 1038.13 1175 1013.79 1199.35 963.59 1249.56 963.19 1249.95 963.59 1250.36 1250 1536.81 1536.66 1250.15 1536.8 1249.99 1536.54 1249.85" />
      </g>
    </svg>
  )
}

export default Binance
