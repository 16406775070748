import React, { useState } from "react"
import { getUser } from "../../../utils/auth"
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../Form/Button';
import TextField from '../../Form/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import Avatar from '../avatar';
import axios from 'axios';

const Step1 = (props) => {
  const [phone, setphone] = useState('')
  const [firstname, setfirstname] = useState('')
  const [lastname, setlastname] = useState('')
  const [displayName, setdisplayName] = useState('')
  const [loading, setLoading] = useState(false)
  const [phoneerror, setphoneerror] = useState(false)
  const user = getUser();

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
      backgroundColor: 'rgba(24,25,26,0.85)',
    },
  }));

  const validatePhone = (p) => {
    if (p && p.match(/\d/g).length === 10 || p.length === 0) {
      setphoneerror(false)
    }else {
      setphoneerror(true)
    }
  }

  const handleSubmit = () => {
    setLoading(true)
    let userObj = {
      createdAt: new Date(user.createdAt),
      emailVerified: user.emailVerified,
      apiKey: user.apiKey,
      email: user.email,
      firstName: firstname || '',
      lastName: lastname || '',
      displayName: displayName || '',
      phone: phone || '',
      id: user.uid
    }
    axios.post('https://us-central1-bitbot-fdaae.cloudfunctions.net/addUser', userObj, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res)=>{
      setLoading(false)
      props.setStep(1)
    }).catch (err => console.log(err))

  }


  const classes = useStyles();

  return (
    <Grid container justify="center">
      <Grid item xs={12} md={8}>
      <Paper className={classes.paper}>
        <Grid container style={{ marginBottom: `25px` }}>
          <Avatar /><Typography variant="h4">{user.email}</Typography>
        </Grid>
        <form>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="displayName"
                name="displayName"
                label="User Display Name"
                value={displayName}
                onChange={(e) => {
                  setdisplayName(e.currentTarget.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="firstname"
                name="firstname"
                label="First Name"
                value={firstname}
                onChange={(e) => {
                  setfirstname(e.currentTarget.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="lastname"
                name="lastname"
                label="Last Name"
                value={lastname}
                onChange={(e) => {
                  setlastname(e.currentTarget.value)
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                id="phone"
                name="phone"
                label="Phone (text alerts)"
                value={phone}
                error={phoneerror}
                helperText="Number only ie. 1235559999"
                onChange={(e) => {
                    validatePhone(e.currentTarget.value)
                  }}
              />
            </Grid>
             
          </Grid>
          <Grid container justify="center" spacing={4}>
            <Grid item xs={10} style={{backgroundColor: `rgba(0,0,0,0.25)`, maxHeight: `400px`, overflowY: `scroll`, padding: `50px`, margin: `25px`, borderRadius: `15px`}}>
              This is in testing mode. Use at your own risk.  On launch, new TOS here.
            </Grid>
          </Grid>
            
            {loading && <div style={{ display: `flex`, width: '100%', justifyContent: `center` }}><div className="lds-facebook"><div></div><div></div><div></div></div></div>}
          <Button onClick={handleSubmit} color="secondary" variant="contained" type="submit" text="I Agree to the Terms of Service">
            Submit
          </Button>
        </form>
      </Paper>
      </Grid>
    </Grid>
  )
}

export default Step1
