import React, {useContext, useState} from "react"
import { getUser } from "../../../utils/auth"
import { useStaticQuery, graphql, navigate} from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import Img from "gatsby-image"
import Button from '../../Form/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import axios from 'axios'
import  {DashContext} from '../Dash/context/DashContextProvider'
const Step3 = (props) => {
  const data = useStaticQuery(imgQuery)
  const [userInfo, setUserInfo] = useContext(DashContext)
  const [loading, setLoading] = useState(false)
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
      backgroundColor: 'rgba(24,25,26,0.55)',
    },
  }));
  const user = getUser();

  const handleSubmit = () => {
    setLoading(true)
    axios.post('https://us-central1-bitbot-fdaae.cloudfunctions.net/userFinishSetup', { id: user.uid }, {headers: {
      'Content-Type': 'application/json'
    }}).then((user) =>{
      setUserInfo(user.data)
      setTimeout(()=>{
        navigate('/app/profile')
        setLoading(false)
      }, 500)
    }).catch(err =>{
      console.log(err)
    })
  }

  const classes = useStyles();
  return (
    <Grid container justify="center" spacing={4}>
      <Grid item xs={12} md={8}>
        <Paper className={classes.paper}>
          <Grid container spacing={8} direction="column" justify="center" align="start">
            <Grid container justify="center">
              <Img fixed={data.tradingviewImg.childImageSharp.fixed} />
            </Grid>
            <Grid item xs={12}>
             
              Now sign up for a TradingView account if you don't already have one.  You can use alerts to control your bot to buy/sell. 

              Once you have an account setup, you can setup alerts and use the webhooks provided for your allocations.
            {loading && <div style={{ display: `flex`, width: '100%', justifyContent: `center` }}><div className="lds-facebook"><div></div><div></div><div></div></div></div>}
             <Button text="Go to your Dashboard" onClick={handleSubmit} variant="primary" />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Step3

const imgQuery = graphql`
  query imgQuery {
    tradingviewImg: file(relativePath: { eq: "tradingview.png" }) {
      childImageSharp {
        fixed(quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`