import React, { useEffect, useState } from "react"
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography } from "@material-ui/core";
import DateJS from 'datejs'

const Trades = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
      backgroundColor: 'rgba(24,25,26,0.55)',
    },
    table: {
      minWidth: 700,
    },
  }));

  const StyledTableCell = withStyles((theme) => ({
    root: {
      paddingLeft: '45px !important',
      paddingRight: '45px !important'
    },
    head: {
      backgroundColor: 'rgba(24,25,26,0.55)',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      padding: '0 25px',
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(24,25,26,0.75)',
      },
      '&:nth-of-type(even)': {
        backgroundColor: 'rgba(24,25,26,0.55)',
      },

    },
  }))(TableRow);

  const classes = useStyles();

  return (
    <>
      <Typography variant="h4">{props.user.displayName}Bot Trade History</Typography>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ACTION</StyledTableCell>
              <StyledTableCell align="right" secondary="Test">PRODUCT</StyledTableCell>
              <StyledTableCell align="right">DATE</StyledTableCell>
              <StyledTableCell align="right">TOTAL VALUE</StyledTableCell>
              <StyledTableCell align="right">PRICE PER UNIT</StyledTableCell>
              <StyledTableCell align="right">QUANTITY</StyledTableCell>
              <StyledTableCell align="right">TRADE FEES</StyledTableCell>
              <StyledTableCell align="right">GAIN / LOSS</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.trades && props.trades.map((trade) => (
              <StyledTableRow key={trade.orderId}>
                <StyledTableCell button="true" component="th" scope="row">
                  <span className={trade.side == 'buy' ? 'buyside' : 'sellside'}>
                   {trade.side}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="right">{trade.symbol}</StyledTableCell>
                <StyledTableCell align="right">{new Date(trade.createdAt._seconds * 1000).toString('M/d/yyyy hh:mm tt')}</StyledTableCell>
                <StyledTableCell align="right">${(trade.price * trade.quantity).toFixed(2)}</StyledTableCell>
                <StyledTableCell align="right">${trade.price.toFixed(2)}</StyledTableCell>
                <StyledTableCell align="right">{trade.quantity}</StyledTableCell>
                <StyledTableCell align="right">{trade.estimatedFees.toFixed(2)}</StyledTableCell>
                <StyledTableCell align="right">
                  <span className='buyside'>
                    {trade.buyPrice && 
                    <>PROFIT ${((trade.price - trade.buyPrice) * trade.quantity).toFixed(2)} </>
                    }
                    
                  </span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
    </TableContainer>
    </>
  )
}

export default Trades


