import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width="341px" height="341px" viewBox="0 0 341 341" {...props}>
      <defs>
        <filter
          x="-14.5%"
          y="-14.5%"
          width="129.0%"
          height="129.0%"
          filterUnits="objectBoundingBox"
          id="a"
        >
          <feGaussianBlur stdDeviation={12} in="SourceGraphic" />
        </filter>
      </defs>
      <g
        transform="translate(33 13)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <circle
          stroke="#3E3E3E"
          strokeWidth={7.5}
          opacity={0.49736759}
          filter="url(#a)"
          cx={137.25}
          cy={157.25}
          r={137.25}
        />
        <circle
          stroke="#3E3E3E"
          strokeWidth={7.5}
          fillOpacity={0.0176481234}
          fill="#FF00C8"
          cx={142.25}
          cy={137.25}
          r={137.25}
        />
        <text
          fontFamily="Mako-Regular, Mako"
          fontSize={37.5}
          fontWeight="normal"
          fill="#FFF"
          fillOpacity={0.9}
        >
          <tspan x={31.2625} y={149}>
            {"ADD ALLOCATION"}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default SvgComponent
