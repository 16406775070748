import React, {useEffect} from "react"
import { isLoggedIn } from "../../utils/auth"
import { navigate } from "gatsby";
import Dash from './Dash/dash'

import { DashProvider } from './Dash/context/DashContextProvider'
const Profile = () => {
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate(`/login`)
    } 
  },[])
  return (
    <DashProvider>
      <Dash /> 
    </DashProvider>
  )
}

export default Profile