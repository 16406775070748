import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import UserProfile from "../components/Profile/userProfile"
import Setup from "../components/Profile/setup"
import SkullGuy from "../components/skull"
import PrivateRoute from "../components/PrivateRoute"
import Login from "../components/login"
import Order from "../components/Order"

const App = () => (
  <Layout>
    <SEO title="Home" />
    <SkullGuy style={{ position: `absolute`, zIndex: '-1', left: '0px', top: '0px' }} />
    <Router>
      <PrivateRoute path="/app/profile" component={UserProfile} />
      <PrivateRoute path="/app/setup" component={Setup} />
      <Login path="/login" />
      <Order path="/app/order/:id/" />
    </Router>
  </Layout>
)

export default App