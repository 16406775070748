export const getAllocationIndex = (allocations, symbol) => {
  const alloCheck = element => element.symbol === symbol.toUpperCase()
  return allocations.findIndex(alloCheck)
}

export const getLastTrade = (trades, symbol) => {
  const tradeCheck = element => element.symbol === symbol.toUpperCase()
  let index = trades.findIndex(tradeCheck)
  if(index !== -1) {
    return trades[index]
  }else {
    return false
  }
}
