import React, { useEffect, useState } from "react"
import { getUser, isLoggedIn } from "../../../utils/auth"
import { navigate, useStaticQuery, graphql, Link} from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import Img from "gatsby-image"
import Button from '../../Form/Button';
import TextField from '../../Form/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios'

const Step2 = (props) => {
  const [apikey, setapikey] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [keyError, setKeyError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [apisecret, setapisecret] = useState('')

  const data = useStaticQuery(footerQuery)
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
      backgroundColor: 'rgba(24,25,26,0.25)',
    },
  }));
  const user = getUser();

  const handleSubmit = () => {
    setLoading(true)
    let apiObj = {
      apiKey: apikey,
      apiSecret: apisecret,
      id: user.uid
    }
    axios.post('https://us-central1-bitbot-fdaae.cloudfunctions.net/binance-addBinance', apiObj, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      setLoading(false)
      props.setStep(2)
    }).catch(err => {
      setKeyError("Invalid Keys - Could not connect.")
    })
  }
  useEffect(() => {
    if(apikey !== '' && apisecret !== '') {
      setDisabled(false)
    }
  }, [apikey, apisecret]);

  const classes = useStyles();
  return (
    <Grid container justify="center" spacing={4}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
        
        <Grid container spacing={8} direction="column" justify="center" align="start">
          <Grid container justify="center">
              <Img fixed={data.binanceImg.childImageSharp.fixed} />
          </Grid>
          <Grid item >
            <Typography>
                Now we need to connect your Binance account using the API keys.  Do you already own a binance.us account?
            </Typography>
          </Grid>
          <Grid item xs={12}>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Yes, I already have a binance account.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h5" gutterBottom>Setup your API access by going following these instructions:</Typography>

                        <Typography variant="h6" gutterBottom>Step 1 </Typography>
                        <Typography gutterBottom>Go to binance.us and sign in, click on your profile and select "API Management"</Typography>

                        <Typography variant="h6" gutterBottom>Step 2  </Typography>
                        <Typography gutterBottom>Give your key a name (ie. Slickbit) and click create.  You'll likely have to go through some verification.</Typography>

                        <Typography variant="h6" gutterBottom>Step 3 </Typography>
                        <Typography gutterBottom>Make sure to enable "Can Read" and "Enable Spot Trading", DO NOT enable withdrawal.</Typography>

                        <Typography variant="h6" gutterBottom>Once you have your API Key and Secret, enter it below and we will verify the connection and encrypt the keys on our server.</Typography>
                      <form style={{ margin: `50px 0` }}>
                        <Grid container spacing={2}>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="apiKey"
                              name="apiKey"
                              label="Binance API Key"
                              value={apikey}
                              error={keyError}
                              helperText={keyError}
                              onChange={(e) => {
                                setKeyError(null)
                                setapikey(e.currentTarget.value)
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id="apiSecret"
                              name="apiSecret"
                              label="Binance API Secret"
                              value={apisecret}
                              error={keyError}
                              helperText={keyError}
                              onChange={(e) => {
                                setKeyError(null)
                                setapisecret(e.currentTarget.value)
                              }}
                            />
                          </Grid>
                          <Grid container justify="flex-end">
                            {loading && <div style={{ display: `flex`, width: '100%', justifyContent: `center` }}><div className="lds-facebook"><div></div><div></div><div></div></div></div>}
                            <Button onClick={ handleSubmit } color={disabled ? 'default' : 'primary'} disabled={disabled} variant="contained" type="submit" text="Verify Connection" />
                          </Grid>
                        </Grid>
                      </form>

                    </Grid>
                    <Grid item xs={6}>
                      <Img style={{ maxWidth: `800px` }} fluid={data.binanceInst.childImageSharp.fluid} />
                    </Grid>

                  </Grid>
                  
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>No, I don't have a binance account yet.</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <a href="https://accounts.binance.us/en/register?ref=53656065" target="_blank"><Button variant="primary" text="Create Binance Account"></Button></a>
                </AccordionDetails>
              </Accordion>
          </Grid>
          
        </Grid>
        
        
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Step2

const footerQuery = graphql`
  query footerQuery {
    binanceImg: file(relativePath: { eq: "binance.png" }) {
      childImageSharp {
        fixed(quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    binanceInst: file(relativePath: { eq: "binanceinstructions.png" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`