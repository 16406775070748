import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '../../Form/Button';
import TextField from '../../Form/TextField';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { Icon } from "@rimble/icons";
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import BitModal from '../../SVG/bitModal'
import copy from 'clipboard-copy'
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios'
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import { DashContext } from './context/DashContextProvider'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import {getAllocationIndex} from '../../../utils/helper'



function getModalStyle() {
  const top = 40;
  const left = 40;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    border: 'none',
    outline: 'none'
  };
}

export default function TemporaryDrawer(props) {
  const [state, setState] = useState({
    right: false,
  });
  const theme = useTheme();

  const useStyles = makeStyles({
    list: {
      width: 300,
    },
    fullList: {
      width: 'auto',
    },
    alloContainer: {
     position: `absolute`, 
     zIndex: `150`, 
     left: '-28vw', 
     top: '-17vh',
      [theme.breakpoints.down("xs")]: {
        top: '-37vh',
        left: '-35vw'
      }
    },
    paper: {
      position: 'absolute',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      [theme.breakpoints.down("xs")]: {
        padding: '25px',
        backgroundColor: '#141617',
      },
      border: 'none',
    },
    cardCode: {
      backgroundColor: 'rgba(0,0,0,0.7)',
      borderRadius: 25,
    },
  });
  const classes = useStyles();
  const [allocation, setAllocation] = useState(0)
  const [locked, setlocked] = useState(false);
  const [activeCrypto, setactiveCrypto] = useState('')
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [urlCopied, seturlCopied] = useState(false)
  const [buyCopied, setbuyCopied] = useState(false)
  const [sellCopied, setsellCopied] = useState(false)
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userInfo, setUserInfo] = useContext(DashContext)
  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
  const handleOpen = (symbol) => {
    setactiveCrypto(symbol)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const copyUrl = () => {
    copy(`https://slickbot.io/botapp/order?id=${props.id}`)
    seturlCopied(true)
    setTimeout(()=> {
      seturlCopied(false)
    }, 2000)
  }
  const copyBuy = () => {
    copy(`{
    "side": "buy",
    "symbol": "${activeCrypto.toUpperCase()}",
    "price": "{{close}}",
    "time": "{{time}}"
}`)
    setbuyCopied(true)
    setTimeout(() => {
      setbuyCopied(false)
    }, 2000)
  }
  const copySell = () => {
    copy(`{
    "side": "sell",
    "symbol": "${activeCrypto.toUpperCase()}",
    "price": "{{close}}",
    "time": "{{time}}"
}`)
    setsellCopied(true)
    setTimeout(() => {
      setsellCopied(false)
    }, 2000)
  }



  const handleSubmit = () => {
    if (parseFloat(allocation) <= parseFloat(props.balances.USD.available)) {
      let updateAllocationObj = {
        id: props.id,
        symbol: activeCrypto.toUpperCase(),
        amount: parseFloat(allocation),
        exchange: 'binance',
        initialValue: parseFloat(allocation),
        locked: locked,
      }
      setLoading(true)
      axios.post('https://us-central1-bitbot-fdaae.cloudfunctions.net/binance-updateAllocations', updateAllocationObj, {
        headers: {
          'content-type': 'application/json'
        }
      }).then((res) => {
        axios.post('https://us-central1-bitbot-fdaae.cloudfunctions.net/getUser', { id: props.id }).then((res) => {
          setUserInfo(res.data)
          setLoading(false)
        }).catch(err => {
          console.log(err)
        })
        setOpen(false);
        toggleDrawer('right', false)
      })
    } else {
      console.log("not enough")
      setError(true)
    }
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    props.setDrawer(open)
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if (props.drawer === true) {
      setState({ ...state, ['right']: true });
    }
  }, [props.drawer])


  const body = (alloindex) => 
  {
    return (
    <div style={modalStyle} className={classes.paper}>
      <div style={{position: `relative`}}>
          <div className="modalclose" onClick={handleClose}>
                X
          </div>
          <div className={classes.alloContainer}>
            <Paper className={classes.paper}>

           
            <Grid container direction="row" spacing={4} style={{minWidth: `57vw`, overFlow: 'scroll', height: '100vh'}}>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={4}>
                  <Grid item xs={12} className="skum" style={{ display: `flex`, alignItems: `center` }}>
                    <Icon name={activeCrypto} size={64} color="black" />
                    <Typography variant="h4" style={{ paddingLeft: '15px' }}>{activeCrypto.toUpperCase()}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{opacity: '0.5'}}>USD Available: ${props.available} </Typography>
                    <Typography variant="h5">
                      Current Allocation: <span className="skum buyside">${alloindex !== -1 ? userInfo.allocations[alloindex].amount : '0'}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                    <form className={classes.root} noValidate autoComplete="off">
                      <div style={{padding: `10px 10px`}}>
                        <Tooltip title="If you lock this amount, the bot won't reinvest beyond what you specify here.  Otherwise it will reinvest the gains made on this allocation." placement="top"><FormControlLabel
                          control={<Switch size="small" checked={checked} onChange={toggleChecked} />}
                          label="Lock Amount (Optional)" />
                        </Tooltip>
                      </div> 
                      <TextField
                        label={alloindex !== -1 ? userInfo.allocations[alloindex].amount : `Enter Amount`}
                        helperText={error ? `Not enough funds available.` : `Total cash you want to allocate to ${activeCrypto.toUpperCase()}`}
                        id="allocation"
                        name="allocation"
                        value={allocation}
                        variant="outlined"
                        error={error}
                        onChange={(e) => {
                          setAllocation(e.currentTarget.value)
                        }}>

                      </TextField>
                      
                      <div style={{display: `flex`}}>
                        {loading && <div style={{ display: `flex`, width: '100%', justifyContent: `center` }}><div className="lds-facebook"><div></div><div></div><div></div></div></div>}
                        <Button onClick={handleSubmit} size="small" style={{ display: `flex`, marginTop: `50px`, position: `relative`, left: `-2em` }} color={allocation > 0 ? 'primary' : 'inherit'} disabled={allocation !== 0 ? false : true} text={allocation ? `Update ${activeCrypto.toUpperCase()}` : `Specify Funds`} />
                      </div>
                    </form>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
                <Grid item xs={12} sm={8}>
                <div style={{display: `flex`, flexDirection: `column`, justifyContent: `flex-start`, alignItems: `flex-start`}}>
                  <Typography style={{ marginBottom: '25px' }}>
                    Webhook URL (same for all coins):<br />
                    <Tooltip
                      open={urlCopied}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Copied to clipboard">
                      <span className="hookurl"> {`https://slickbot.io/botapp/order?id=${props.id}`}</span>  
                    </Tooltip>
                    <IconButton onClick={() => copyUrl()} aria-label="copy" size="small" style={{opacity: '0.25'}}>
                      <FileCopyOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Typography>
                  <Typography style={{marginBottom: '25px'}}>
                    TradingView Webhook "Message" field content in buy/sell alerts
                  </Typography>
                </div>
                <div style={{display: `flex`}}>
                  <Grid item xs={12} sm={6} style={{paddingRight: '25px'}}>
                    <Typography className="skum buyside">Buy</Typography>
                    <Card className={classes.cardCode}>
                      <CardContent>
                      <Grid container spacing={0} direction="column" className="codeFont">
                          <Grid item xs={12} >
                            {`{`}
                          </Grid>
                          <Grid item xs={12}>
                            &nbsp; &nbsp; {` "side": "buy",`}
                          </Grid>
                          <Grid item xs={12}>
                            &nbsp; &nbsp;{` "symbol": "${activeCrypto.toUpperCase()}",`}
                          </Grid>
                          <Grid item xs={12}>
                            &nbsp; &nbsp; {` "price": "{{close}}",`}
                          </Grid>
                          <Grid item xs={12}>
                            &nbsp; &nbsp;  {` "time": "{{time}}"`}
                          </Grid>
                          <Grid item xs={12}>
                            {`}`}
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions style={{display: `flex`, justifyContent: `flex-end`, opacity:"0.25"}}>
                        <Tooltip
                          open={buyCopied}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Copied to clipboard"
                        >
                          <IconButton aria-label="copy" size="small" onClick={() => copyBuy()}>
                            <FileCopyOutlinedIcon fontSize="small"/>
                          </IconButton>
                        </Tooltip>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className="skum sellside">Sell</Typography>
                    <Card className={classes.cardCode}>
                      <CardContent>
                        <Grid container spacing={0} direction="column" className="codeFont">
                          <Grid item xs={12}>
                            {`{`}
                          </Grid>
                          <Grid item xs={12}>
                            &nbsp; &nbsp; {` "side": "sell",`}
                          </Grid>
                          <Grid item xs={12}>
                            &nbsp; &nbsp;{` "symbol": "${activeCrypto.toUpperCase()}",`}
                          </Grid>
                          <Grid item xs={12}>
                            &nbsp; &nbsp; {` "price": "{{close}}",`}
                          </Grid>
                          <Grid item xs={12}>
                            &nbsp; &nbsp;  {` "time": "{{time}}"`}
                          </Grid>
                          <Grid item xs={12}>
                            {`}`}
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions style={{ display: `flex`, justifyContent: `flex-end`, opacity: "0.25" }}>
                        <Tooltip
                          open={sellCopied}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Copied to clipboard"
                        >
                          <IconButton aria-label="copy" size="small" onClick={() => copySell()}>
                            <FileCopyOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </CardActions>
                    </Card>
                  </Grid>
              </div>
              </Grid>
            </Grid>
            </Paper>
          </div>
          <BitModal className="mobilehide" style={{position: `absolute`, transform: 'translate(-50%, -40%)', maxWidth: `77vw`}} />
      </div>
    </div>
  )};

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, true)}
    >
      <Modal
        style={{outline: 'none'}}
        open={open}
        onClose={handleClose}
        aria-labelledby="Select Crypto Allocation"
        aria-describedby="simple-modal-description"
      >
        {body(getAllocationIndex(userInfo.allocations, activeCrypto))}
      </Modal>
      <List>
        {props.cryptos.map((crypto, index) =>{ 
          
          let symbol = crypto.symbol.charAt(0).toUpperCase() + crypto.symbol.slice(1).toLowerCase()
          return (
          <ListItem button key={index} onClick={() => handleOpen(symbol)} >
            <ListItemIcon>
              <Icon name={symbol} size={32}/>
            </ListItemIcon>
            <ListItemText primary={crypto.symbol} secondary={crypto.available} />
          </ListItem>
        )
        })}
      </List>
    </div>
  );

  return (
    <div>
      <div style={{ position: `fixed`, bottom: '10px', right: '0' }}>
      <Button variant="primary" size="large" text={'Add Allocation'} onClick={toggleDrawer('right', true)} />
      </div>
      <React.Fragment>
        <Drawer anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)}>
          {list('right')}
        </Drawer>
      </React.Fragment>
    </div>
  );
}