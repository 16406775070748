import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SettingsIcon from '@material-ui/icons/Settings';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '../Form/Button';
import Typography from '@material-ui/core/Typography';
import Binance from '../SVG/binance';
import Grid from '@material-ui/core/Grid';
import Step1 from './Setup/step1'
import Step2 from './Setup/step2'
import Step3 from './Setup/step3'
import { DashProvider } from './Dash/context/DashContextProvider'

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 45deg,rgb(13,255,21) 0%, rgb(0, 209, 187) 80%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 45deg,rgb(13,255,21) 0%, rgb(0, 209, 187) 80%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 90deg, rgb(13,255,21) 0%,   rgb(0, 209, 187) 80%)',
    boxShadow: '0 14px 28px 0 rgba(0,209,187,.45)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 90deg, rgb(13,255,21) 0%,   rgb(0, 209, 187) 80%)',
      boxShadow: '0 4px 8px 0 rgba(0,209,187,.45)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <Binance maxwidth="25px" color="#FFF"/>,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  paper: {
    background: 'none',
  },
  stepper: {
    backgroundColor: 'none',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['TERMS & CONDITIONS', 'CONNECT BINANCE', 'AUTOMATE'];
}


export default function CustomizedSteppers(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep <= 1) {
      setActiveStep(0)
    }else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <Step1 setStep={setActiveStep} />;
      case 1:
        return <Step2 setStep={setActiveStep} />;
      case 2:
        return <Step3 updateUser={props.updateUser} />;
      default:
        return 'Unknown step';
    }
  }


  return (
    <DashProvider>
      <Grid container justify="center">
        <Grid item xs={12} md={10} >
          <div className={classes.root}>
            <Stepper style={{ background: `none` }} alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
            </Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    Reset
            </Button>
                </div>
              ) : (
                  <div>
                    <Grid direction="row" justify="center" container className={classes.instructions}>
                      <Grid item xs={12} md={10}>
                        {getStepContent(activeStep)}
                      </Grid>
                    </Grid>
                    {/* <Grid justify="center" direction="row" container spacing={2}>
                <Button text="Back" color="tertiary" disabled={activeStep === 0} onClick={handleBack} className={classes.button} />
                <Button
                  variant="contained"
                  color="primary"
                  text={activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  onClick={handleNext}
                  className={classes.button}
                />
              </Grid> */}
                  </div>
                )}
            </div>
          </div>

        </Grid>
      </Grid>
    
    </DashProvider>
  );
}