import React from 'react';
import axios from 'axios';

const Order = (props) => {
  return (
    <>
      {props.id}
    </>
  );

}
export default Order

