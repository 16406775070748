import React, {useState, useEffect, createContext} from 'react'
import { getUser, isLoggedIn } from "../../../../utils/auth"
import axios from 'axios'
import SkullLoad from '../../../skullLoad'
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';

export const DashContext = createContext();

export const DashProvider = props => {
  const [userInfo, setUserInfo] = useState({
    displayName: 'NA',
    setup: false,
    allocations: []
  })
  const [loading, setLoading] = useState(true)

  const user = getUser();
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
      backgroundColor: 'rgba(24,25,26,0.85)',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      backgroundColor: 'rgba(0,0,0,0.95)'
    },
  }));

  const classes = useStyles();
  useEffect(() => {
    axios.post('https://us-central1-bitbot-fdaae.cloudfunctions.net/getUser', { id: user.uid }).then((res) => {
      res.data.id = user.uid
      setUserInfo(res.data)
      setLoading(false)
      if(res.data.setup){
        navigate('/app/profile')
      }
    }).catch(err => {
      console.log(err)
    })
  }, [user.uid])


  return (<DashContext.Provider 
            value={[userInfo, setUserInfo, loading, setLoading]}
            >
              {loading && <Backdrop className={classes.backdrop} open={loading}>
                  <div style={{ display: `flex`, justifyContent: `center`, alignItems: `center`, flexDirection: `column` }}>
                    <SkullLoad maxwidth="600px" />
                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                    <h1 className="skum center">Getting Datazzz...</h1>
                  </div>
                </Backdrop>
              }
              {props.children}</DashContext.Provider>)
}
