import React, { useEffect, useState, useContext } from "react"
import { getUser } from "../../../utils/auth"
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ProfileHeader from '../../SVG/profileHeader'
import Trades from './trades'
import axios from 'axios'
import Drawer from './drawer'
import DashDial from './dial'
import { getLastTrade } from '../../../utils/helper'
import AddAllocation from '../../SVG/addAllocation';
import Chart from './chart'
import { DashContext } from './context/DashContextProvider'
import _ from 'lodash'

const Dash = () => {
  const [userInfo, setUserInfo] = useContext(DashContext)

  const [crypto, setCrypto] = useState([])
  const [currentHoldings, setCurrentHoldings] = useState([])
  const [currentValue, setCurrentValue] = useState(0)
  const [accountValue, setAccountValue] = useState({})
  const [gainlossperc, setgainlossperc] = useState(0)
  const [balances, setBalances] = useState({USD: {available: 0}})
  const [trades, setTrades] = useState([])
  const [allocated, setAllocated] = useState(0)
  const [startingValue, setStartingValue] = useState(0)
  const [drawer, setDrawer] = useState(false)
  const [dials, setDials] = useState([])

  const user = getUser();
  const colors = ["#FF00C8", "#0DFF15", "#00FFE5", "#FF940D", "#0002FF", "#FF2E54", "#FFDD00", "#97FFF8", "#4A00FF", "#94FF50"]

  const calcGainLossPerc = (value, deposit) => {
    if (value !== 0 && deposit !== 0) {
      value = parseFloat(value)
      deposit = parseFloat(deposit)
      return (((value - deposit) / deposit) * 100).toFixed(0)
    } else {
      return 0
    }
  }

  const getAvailableBalance = () => {
    let usdAvailable = parseFloat(balances.USD.available)
    let alreadyAllocated = 0
    userInfo.allocations.forEach(allocation => {
      alreadyAllocated = alreadyAllocated + parseFloat(allocation.amount)
    })
    setAllocated(usdAvailable - alreadyAllocated)
  }

  useEffect(()=>{
    getAvailableBalance()
  }, [balances])

  useEffect(()=> {
    axios.post('https://us-central1-bitbot-fdaae.cloudfunctions.net/binance-getBalances', { id: user.uid }, {
      headers: {
        'content-type': 'application/json'
      }
    }).then(res => {
      let accountKeys = Object.keys(res.data)
      let holding = []
      let coins = []
      accountKeys.forEach(account => {
        if (res.data[account].available > 0) {
          holding.push({
            symbol: account,
            available: res.data[account].available,
            onOrder: res.data[account].onOrder
          })
        }
      })
      setBalances(res.data)
      setCurrentHoldings(holding)

      accountKeys.forEach(account => {
        if(account !== 'USD' && account !== 'USDT' && account !== 'BUSD' && account !== 'USDC'){
          coins.push({
            symbol: account,
            available: res.data[account].available
          })
        }
      })
      setCrypto(_.orderBy(coins, ['symbol'], ['asc']))
      //props.loading(false)
    })
  }, [])

  useEffect(()=> {
    axios.post('https://us-central1-bitbot-fdaae.cloudfunctions.net/binance-getTrades', { id: user.uid }, {
      headers: {
        'content-type': 'application/json'
      }
    }).then(res => {
      setTrades(res.data)
    })

  }, [])

  const addAllo = () =>{
    setDrawer(true)
  }


  useEffect(()=> {
    axios.post('https://us-central1-bitbot-fdaae.cloudfunctions.net/binance-getAccountValue', { id: user.uid }, {
      headers: {
        'content-type': 'application/json'
      }
    }).then(res => {
      setAccountValue(res.data.cryptoValue)
      setCurrentValue(res.data.currentValue.toFixed(2))
      setStartingValue(res.data.depositValue.toFixed(2))
      setgainlossperc(calcGainLossPerc(res.data.currentValue, res.data.depositValue))
      
    })
  }, [user.uid])
  
  useEffect(() => {
    let alloDials = []
    userInfo.allocations.forEach(allocation => {
      let value = 0
      let active = false
      if (accountValue.hasOwnProperty(allocation.symbol) && accountValue[allocation.symbol].value > 0.99) {
        value = accountValue[allocation.symbol].value
        active = true
      } else {
        value = allocation.amount
      }
      let findLastTrade = getLastTrade(trades, allocation.symbol)
      let gainLoss = calcGainLossPerc(value, allocation.initialValue)
      let lastTrade = false
      let lastSignal = false

      if(findLastTrade){
        lastTrade = { time: findLastTrade.createdAt._seconds * 1000, qty: findLastTrade.executedQty, price: findLastTrade.price, side: findLastTrade.side }
        lastSignal = { time: findLastTrade.signalTime._seconds * 1000, price: findLastTrade.signalPrice, side: findLastTrade.side }
      }


      alloDials.push({
        value,
        active: active,
        symbol: allocation.symbol,
        lastTrade,
        lastSignal,
        initialValue: allocation.initialValue,
        exchange: allocation.exchange,
        gainLoss,
        gainLossValue: value - allocation.initialValue
      })
    })
    setDials(alloDials)
  }, [accountValue, trades, userInfo.allocations])
  

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: '25px'
    },
    paper: {
      padding: theme.spacing(4),
      color: theme.palette.text.secondary,
      backgroundColor: 'rgba(24,25,26,0.55)',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    accordion: {
      backgroundColor: 'rgba(24,25,26,0.55)'
    },
  }));

  const classes = useStyles();
 
  return (
    <>
      <div>
        <div className={classes.root}>
          <Grid justify="center" container spacing={2}>
            <Grid item xs={12} xl={10}>
              {/* <span style={{ display: `flex`, position: `relative`, width: '13vw', height: '2vh', top: '17em', left: '20em', zIndex: '99999'}}>
                <Chart id={userInfo.id}/>
              </span> */}
              <ProfileHeader displayname={userInfo ? userInfo.displayName : ''} currentvalue={currentValue} startingvalue={startingValue} gainlossperc={gainlossperc} />
            </Grid>
            
            
            <Grid container spacing={2} style={{position: `relative`, top: '-2em'}}>
              <Grid item xs={12}>
                <Typography variant="h4" style={{ display: `flex`, justifyContent: `center`, alignItems: `center`, flexWrap: `wrap` }}>
                  Current Allocations <span style={{display: `flex`, alignItems: `center`, fontSize: `24px`, opacity: '0.8', margin: '0 5px', paddingTop: '5px' }} >(${parseInt(allocated)} of ${parseInt(balances.USD.available)} Available)</span>
                </Typography>
                <Typography style={{display: `flex`, opacity: '0.5'}}>
                
                </Typography>
              </Grid>
              
              {userInfo.allocations.length === 0 && 
                <Grid container justify="center" spacing={2}>
                  <Grid item xs={4} style={{ display: `flex`, justifyContent: `center` }}>
                  <AddAllocation style={{ cursor: `pointer` }} onClick={addAllo}/>
                  </Grid>
                </Grid> 
              }
              <Grid container className="centerorstart" spacing={2}>
                {userInfo.allocations.length > 0 && dials.map((dial, i) => {
                  return(
                    <Grid key={i} item xs={4}>
                      <DashDial 
                        asset={dial.symbol} 
                        gainlossperc={dial.gainLoss} 
                        value={parseFloat(dial.value).toFixed(2)} 
                        color={colors[i]} 
                        exchange={dial.exchange}
                        active={dial.lastTrade.side === 'buy' ? true : false}
                        startingvalue={dial.initialValue}
                        gainloss={(dial.gainLossValue).toFixed(2)}
                        lasttrade={dial.lastTrade ? `${dial.lastTrade.side.toUpperCase()}  ${new Date(dial.lastTrade.time).toString('M/d/yyyy hh:mm tt')}`  : 'NA'}
                        lastsignal={dial.lastSignal ? `${dial.lastSignal.side.toUpperCase()} ${new Date(dial.lastSignal.time).toString('M/d/yyyy hh:mm tt')}`  : 'NA'}

                        />
                    </Grid>
                    )
                  }
                )}
              </Grid>
            </Grid>
            {/* <Grid container justify="center" spacing={2}>
              <Grid item xs={2}>
                <Typography variant="h4">
                  Current Holdings
                </Typography>
              </Grid>
              <Grid container justify="center" spacing={2}>
                {currentHoldings.map((holding, i) => (
                  <Grid key={i} item xs={2}>
                    <Paper className={classes.paper}>
                      {`${holding.symbol} - ${holding.available} - ${holding.onOrder}`}
                    </Paper>
                  </Grid>
                  ) 
                )}
            

              </Grid>
            </Grid> */}
            <Grid item xs={12} xl={10} >
                <Grid container justify="center" spacing={4}>
                <Drawer cryptos={crypto} available={getAvailableBalance} id={user.uid} balances={balances} user={userInfo} drawer={drawer} setDrawer={setDrawer} available={parseInt(allocated)} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={8}>
              <Trades user={userInfo} id={user.uid} trades={trades} />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}

export default Dash